import CustomButton from '../../CustomButton';

const DeleteUser = ({
  handleClose,
  handleDelete,
  data,
  confirmText = false,
  title = 'Delete User',
  subTitle = `Do you want to delete the user ${data?.email} from Mudd-1? This action can not be reversed.`,
  className
}) => {
  
  return (
    <div className={`modal_main notification delete_notification active ${className}`}>
      <div className='modal'>
        <div className='modal_detail'>
          <div className='modal_title'>
            <p>{title}</p>
          </div>
          <div className='modal_desc'>
            <p>{subTitle}</p>
          </div>
          {confirmText && (
            <div className='modal_info-text'>
              <p>{confirmText}</p>
            </div>
          )}
          {confirmText && (
            <div className='modal_confirm-text'>
              <p>Do you want to proceed?</p>
            </div>
          )}
          <div class='notification_btn'>
            <CustomButton
              className='primary notification_button'
              value='No'
              name='submit'
              type={'submit'}
              onClick={() => handleClose({})}
            />
            <CustomButton
              className='clear notification_button notification_clear'
              value='yes'
              name='submit'
              type={'submit'}
              onClick={() => handleDelete(data.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
