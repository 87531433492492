import React from "react";
import { Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Loader from "../../Layout/Loader";
import { isAuthenticated, isClientAuthenticated } from "../../utils";
import { PublicRouteList } from "../data/routes";
import AdminLayout from "../../Layout/AdminLayout";

const publicRouter = (handleNavigate) => {

    const PublicRoutes = ({ children }) => {
        let token = isAuthenticated();
        let clientToken = isClientAuthenticated();
        if (token) {
            return <Navigate to={'/user'} />;
        } else if (clientToken) {
            return <Navigate to={'/client'} />;
        }
        return children;
    };

    return (
        PublicRouteList.map(({ component: Component, path }, key) => {
            return <Route
                path={path}
                element={
                    <PublicRoutes>
                        <AdminLayout handleNavigate={handleNavigate}>
                        <React.Suspense fallback={<Loader />}>
                            <Component handleNavigate={handleNavigate} />
                            </React.Suspense>
                        </AdminLayout>
                    </PublicRoutes >
                }
                key={key}
            />
        })
    )
};

export default publicRouter
