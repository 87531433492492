export const rules = {
  setting_name: [
    {
      required: true,
      message: "Please input name!",
    },
  ],
  clientDefineId: [
    {
      required: true,
      message: "Please add Client ID!",
    },
  ],
  name: [
    {
      required: true,
      message: "Please input client name!",
    },
  ],
  notes: [
    {
      required: true,
      message: "Please input notes!",
    },
  ],
  file_name: [
    {
      required: true,
      message: "Please upload a file!",
    },
  ],
  address: [
    {
      required: true,
      message: "Please input address!",
    },
  ],
  city: [
    {
      required: true,
      message: "Please input city!",
    },
  ],
  zip: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        var isValidZip = /^\d{5,6}$/;
        if (!value) {
          return Promise.reject(new Error("Please enter a zip code!"));
        } else if (!isValidZip.test(value)) {
          return Promise.reject(new Error("Please enter a valid zip code!"));
        }
        return Promise.resolve();
      },
    }),
  ],
  country: [
    {
      required: true,
      message: "Please input country!",
    },
  ],
  state: [
    {
      required: true,
      message: "Please input state!",
    },
  ],
  name: [
    {
      required: true,
      message: "Please input client name!",
    },
  ],
  contact_name: [
    {
      required: true,
      message: "Please input contact name!",
    },
  ],
  manufacturer: [
    {
      required: true,
      message: "Please input manufacturer!",
    },
  ],
  franchise: [
    {
      required: true,
      message: "Please input franchise!",
    },
  ],
  contact_phone: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        var isValidPhone = /^[0-9]{10}$/;
        if (!value) {
          return Promise.reject(new Error("Please enter a phone number!"));
        } else if (!isValidPhone.test(value)) {
          return Promise.reject(new Error("Please enter a valid phone number!"));
        }
        return Promise.resolve();
      },
    }),
  ],
  contact_email: [
    {
      type: "email",
      message: "Please input valid email!",
    },
    {
      required: true,
      message: "Please input email!",
    },
  ],
  email: [
    {
      type: "email",
      message: "Please input valid email!",
    },
    {
      required: true,
      message: "Please input email!",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your password!",
    },
  ],
  currentPassword: [
    {
      required: true,
      message: "Please enter your  current password!",
    },
  ],
  newPassword: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (!value) {
          return Promise.reject("Please enter your new password");
        } else if (!re.test(value)) {
          return Promise.reject(
            "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, one number and one special case letter."
          );
        } else if (!value || getFieldValue("currentPassword") !== value) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error("New password cannot be same as current password!"));
        }
      },
    }),
  ],
  confirmPassword: [
    {
      required: true,
      message: "Please enter your confirm password",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("newPassword") === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("The two passwords that you entered do not match!"));
      },
    }),
  ],
  LMS: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue("MI4") || value) {
          return Promise.resolve();
        } else {
          return Promise.reject("Please select LMS or MI4!");
        }
      },
    }),
  ],
  fname: [
    {
      required: true,
      message: "Please input first name!",
    },
  ],
  lname: [
    {
      required: true,
      message: "Please input last name!",
    },
  ],
  role: [
    {
      required: true,
      message: "Please select role!",
    },
  ],
};
