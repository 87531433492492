import axiosAdmin from '../../api/axiosAdmin';
import CustomNotification from '../../components/CustomNotification';

const vinModel = {
  state: {
    vinList: {}
  },
  reducers: {
    setVinDecoderData(state, payload) {
      return { ...state, [payload.name]: { ...payload.data } };
    }
  },
  effects: (dispatch) => ({
    async getVinFiles(payload) {
      return await axiosAdmin
        .get(`/api/client/vindecoder/get`)
        .then((res) => {
          if (res.data.code === 200) {
            this.setVinDecoderData({ name: 'vinList', data: res.data.data });
            return res.data.data;
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async uploadFile(payload) {
      const { data, setFile, updateFiles } = payload;
      await axiosAdmin
        .post(`/api/file/upload?type=${data?.type}`, data.file)
        .then((res) => {
          if (res.data.code === 200) {
            this.addVinFileList({
              data: { file: res.data.data._id },
              updateFiles
            });
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        })
    },
    async addVinFileList(payload) {
      const { data, updateFiles } = payload;
      await axiosAdmin
        .post(`/api/client/vindecoder`, data)
        .then(async (res) => {
          if (res.data.code === 200) {
            CustomNotification({
              date: true,
              title: 'VIN File Request Submitted',
              subTitle: 'Once the file has been processed, it will be available here. ',
              dispatch
            })
            let data = await this.getVinFiles({});
            updateFiles(data);
          } else {
            CustomNotification({
              title: 'Error',
              subTitle: res.data.data,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    }
  })
};

export default vinModel;
