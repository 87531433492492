import React from "react";
import CustomModal from "../../components/CustomModal";
import ChangePassword from "../../views/ChangePassword";

function Menu({ user, changePasswordPop, logOut, togglePwd, handlePwdPop, changePassword }) {

  return (
    <>
      <div className="profile_logout">
        <div className="logout_detali">
          <ul className="profile_detali">
            <li className="email_name">
              <a to="#">{user?.email}</a>
            </li>
            <li onClick={() => changePasswordPop()}>
              <span>Change Password</span>
            </li>
            <li onClick={logOut}>
              <a to="#">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
      <CustomModal visible={togglePwd} onCancel={() => handlePwdPop()} >
        <ChangePassword
          changePassword={changePassword}
          handlePwdPop={() => handlePwdPop()}
        />
      </CustomModal>
    </>
  );
}

export default Menu;
