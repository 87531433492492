import { commanImage } from "../../../assets";
import CustomButton from "../../CustomButton";

const AddedUserPop = ({ title, subTitle, onCancel }) => {
  return (
    <div className="modal">
        <div className="modal_detail">
          <div className="modal_close" onClick={() => onCancel()}>
            <img src={commanImage.close} alt="close" />
          </div>
          <div className="modal_title">
            <p>{title}</p>
          </div>
          <div className="modal_desc">
            {subTitle}
          </div>
          <div className="notification_btn">
            <CustomButton
              className="primary notification_button"
              value="OK"
              name="submit"
              type={"submit"}
              onClick={() => onCancel()}
            />
          </div>
        </div>
      </div>
  )
}

export default AddedUserPop