import React from "react";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import { commanImage } from "../../assets/index";
import { isAuthenticated, isClientAuthenticated } from "../../utils";

function Header({ handlePwdPop, logOut, changePasswordPop, togglePwd, user, changePassword }) {

    return (
        <>
            <div className={`${(isAuthenticated() || isClientAuthenticated()) ? "header header_dashboard" : "header"}`}>
                <div className="container">
                    <div className="header_menu">
                        <div className="header_logo">
                            <Link to="#">
                                <img src={commanImage.muddlogo} alt="headerImage" />
                            </Link>
                        </div>
                        <div className="header_title">
                            <h1>Mudd-1 Cloud</h1>
                        </div>
                        {(isAuthenticated() || isClientAuthenticated()) && (
                            <div className="header_admin">
                                <div className="login_user_name">
                                    <p>
                                        {user.email}
                                    </p>
                                </div>
                                <div className="login_profile">
                                    <div className="login_user_profile">
                                        <img
                                            src={commanImage.profile_image}
                                            alt="profile"
                                        />
                                    </div>
                                    <Menu
                                        user={user}
                                        changePasswordPop={changePasswordPop}
                                        logOut={logOut}
                                        togglePwd={togglePwd}
                                        handlePwdPop={handlePwdPop}
                                        changePassword={changePassword}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
