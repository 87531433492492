import Modal from "antd/es/modal";

const CustomModal = ({ visible, onCancel, children, className }) => {
    return (
        <Modal closable={false} className={`custom-modal ${className}`} footer={false} open={visible} onCancel={onCancel}>
            {children}
        </Modal>
    )
};

export default CustomModal