import { lazy } from "react";
const JobDropsPage = lazy(() => import("../../views/lms/JobDropsPage"));
const Access403 = lazy(() => import("../../views/Access403"));
const NcoaPage = lazy(() => import("../../views/lms/NcoaPage"));

const VinDecoderPage = lazy(() => import("../../views/lms/VinDecoderPage"));
const FileListing = lazy(() => import("../../components/FileListing"));
const User = lazy(() => import("../../views/User"));
const ClientList = lazy(() => import("../../views/ClientList"));
const DmsStats = lazy(() => import("../../views/DmsStats"));
const SignIn = lazy(() => import("../../views/SignIn"));
const ForgetPassword = lazy(() => import("../../views/ForgetPassword"));
const ChangePassword = lazy(() => import("../../views/ChangePassword"));
const ResetPassword = lazy(() => import("../../views/ResetPassword"));
const Client = lazy(() => import("../../views/lms/Client"));
const AddClient = lazy(() => import("../../views/lms/Client/AddClient"));
const AddJob = lazy(() => import("../../views/lms/Client/ClientWrapper/JobDrops/AddJobs/index"));
// const ClientManager = lazy(() =>
//   import("../../views/lms/Client/ClientManager")
// );
const GlobalSettings = lazy(async () =>
  import("../../views/lms/Client/GlobalSettings")
);

const ClientEdit = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/ClientEdit")
);
const ClientJobs = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/JobDrops")
);
const ClientExport = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/Export")
);
const AdvancedSettings = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/AdvancedSettings")
);

const CleanFiles = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/CleanFiles")
);
const AddCleanFiles = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/AddCleanFiles")
);

const NcoaFiles = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/Ncoa")
);

const AddNcoaFiles = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/AddNcoaFiles")
);
const AddReNcoa = lazy(async () =>
  import("../../views/lms/Client/ClientWrapper/AddReNcoa")
);

const Page1 = lazy(() => import("../../views/mi4/Page1"));
const Page2 = lazy(() => import("../../views/mi4/Page2"));

export const PrivateRouteList = [
  {
    path: "/user",
    exact: true,
    name: "Users",
    component: User,
    title: "User",
  },
  {
    path: "/client-list",
    exact: true,
    name: "ClientList",
    component: ClientList,
    title: "Client List",
  },
  {
    path: "/dms-stats",
    exact: true,
    name: "DmsStats",
    component: DmsStats,
    title: "DMS Stats Download",
  },
  {
    path: "/vin-decoder",
    exact: true,
    name: "VinDecoderPage",
    component: VinDecoderPage,
    title: "VinDecoder",
  },
  {
    path: "/changepassword",
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
    title: "ChangePassword",
  },

];

export const ClientRouteList = [
  {
    path: "/client/settings",
    exact: true,
    name: "Global Settings",
    component: GlobalSettings,
    title: "Global Settings",
    accessType: ["LMS"]
  },
  {
    path: "/access",
    exact: true,
    name: "access",
    component: Access403,
    title: "NCOA",
    layout: "custom",
    accessType: ["LMS", "MI4"]
  },
  {
    path: "/client",
    exact: true,
    name: "Client",
    component: Client,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id",
    exact: true,
    name: "Client",
    component: ClientEdit,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/jobs",
    exact: true,
    name: "Client",
    component: ClientJobs,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/export",
    exact: true,
    name: "Client",
    component: ClientExport,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/settings",
    exact: true,
    name: "Client",
    component: AdvancedSettings,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/clean-files",
    exact: true,
    name: "Client",
    component: CleanFiles,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/add-clean-file",
    exact: true,
    name: "Client",
    component: AddCleanFiles,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/ncoa",
    exact: true,
    name: "Client",
    component: NcoaFiles,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/add-ncoa-file",
    exact: true,
    name: "Client",
    component: AddNcoaFiles,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/add-re-ncoa",
    exact: true,
    name: "Client",
    component: AddReNcoa,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/re-ncoa",
    exact: true,
    name: "Client",
    component: AdvancedSettings,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/add",
    exact: true,
    name: "Client",
    component: AddClient,
    title: "Client",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/jobs/:nid",
    exact: true,
    name: "addjob",
    component: AddJob,
    title: "AddJob",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/jobs/latest",
    exact: true,
    name: "addjob",
    component: AddJob,
    title: "AddJob",
    accessType: ["LMS"]
  },
  {
    path: "/client/:id/jobs/:nid/:qid",
    exact: true,
    name: "editjob",
    component: AddJob,
    title: "editjob",
    accessType: ["LMS"]
  },
  {
    path: "/clean-file-list/:cid/:fid",
    exact: true,
    name: "File Listing",
    component: FileListing,
    title: "File Listing",
    layout: "custom",
    pageType: "CleanFiles",
    accessType: ["LMS"]
  },
  {
    path: "/job-drop-list/:cid/:fid",
    exact: true,
    name: "File Listing",
    component: FileListing,
    title: "File Listing",
    layout: "custom",
    pageType: "JobDrop",
    accessType: ["LMS"]
  },
  {
    path: "/ncoa-file-list/:cid/:fid",
    exact: true,
    name: "File Listing",
    component: FileListing,
    title: "File Listing",
    layout: "custom",
    pageType: "NcoaFiles",
    accessType: ["LMS"]
  },
  {
    path: "/dms-stats/user",
    exact: true,
    name: "DmsStatsClient",
    component: DmsStats,
    title: "DMS Stats Download Client",
    accessType: ["LMS"]
  },
  {
    path: "/job-drops",
    exact: true,
    name: "JobDrops",
    component: JobDropsPage,
    title: "JobDrops",
    accessType: ["LMS"]
  },

  {
    path: "/ncoa-list",
    exact: true,
    name: "Ncoa",
    component: NcoaPage,
    title: "Ncoa",
    accessType: ["LMS"]
  },

  // {
  //   path: "/vin-decoder",
  //   exact: true,
  //   name: "VinDecoderPage",
  //   component: VinDecoderPage,
  //   title: "VinDecoder",
  //   accessType: ["LMS"]
  // },
  {
    path: "/page1",
    exact: true,
    name: "page",
    component: Page1,
    title: "NCOA",
    accessType: ["MI4"]
  },
  {
    path: "/page2",
    exact: true,
    name: "ncoa",
    component: Page2,
    title: "NCOA",
    accessType: ["MI4"]
  },
];

export const PublicRouteList = [
  {
    path: "/",
    exact: true,
    name: "SignIn",
    component: SignIn,
    title: "SignIn",
  },
  {
    path: "/forget-password",
    exact: true,
    name: "ForgetPassword",
    component: ForgetPassword,
    title: "ForgetPassword",
  },
  {
    path: "/reset-password/:token",
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
    title: "ResetPassword",
  },
];
