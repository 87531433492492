import { Alert } from 'antd';

const ErrorComponent = ({error}) => {
    return (
        <div className="error-fallback">
            <Alert
                description={"Sorry for the inconvenience. The page is crashed try to reload the page or contact admin."}
                type="info"
            />
            <Alert
                description={error.toString()}
                type="error"
            />
        </div>
    )
};

export default ErrorComponent