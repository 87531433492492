import React from 'react';
import { Input } from 'antd';

export default function CustomPassword({ form, ...rest }) {
  let error = form?.getFieldError(rest.name)[0];
  let value = form?.getFieldValue(rest.name);

  return (
    <>
      <div
        className={`user_input_detail ${
            error ||
            !value
            ? ''
            : 'active'
        }`}
      >
        <label className='user_label'>{rest.label}</label>
        <Input.Password
          {...rest}
        />
      </div>
    </>
  );
}
