import React from "react";
import { Link } from "react-router-dom";
import { commanImage } from "../../assets/index";

function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer_main">
                    <div className="footer_logo">
                        <Link to="">
                            <picture>
                                <img src={commanImage.moblie_footer_logo} alt="footer_logo" />
                            </picture>
                        </Link>
                    </div>
                    <div className="footer_contact">
                        <p className="contact_title">Contact Support</p>
                        <a href="tel:800.212.2222" className="contact_number">
                            <p>800.212.2222</p>
                        </a>
                        <p className="emali_text">admin@mudd.com</p>
                    </div>
                </div>
                <div className="footer_copy_right">
                    <p>2022, Mudd Advertising, all rights reserved</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
