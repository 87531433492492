import axiosAdmin from "../../api/axiosAdmin";
import AddedUserPop from "../../components/ActionPop/AddedUserPop";
import CustomNotification from "../../components/CustomNotification";
import Cookies from 'universal-cookie';
import { expireTime } from "../../utils";

const cookies = new Cookies();

const userModel = {
    state: {
        docs: [],
        page: 0,
        totalDocs: 0,
        limit: 10,
    },
    reducers: {
        setUserData(state, payload) {
            return { ...state, ...payload  } ;
        },
    },
    effects: (dispatch) => ({
        async getAllUsers(payload) {
            await axiosAdmin.post(`/api/admin/users/get`, payload).then(res => {
                if (res.data.code === 200) {
                    this.setUserData(res.data.data);
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async addUser(payload) {
            const { formData, handleModal } = payload;
            await axiosAdmin.post('/api/admin/users', formData).then(res => {
                if (res.data.code === 200) {
                    handleModal(null, false);
                    dispatch.notiModel.setModalData({ 
                        Component: <AddedUserPop
                        title={"Add A System User"}
                        subTitle={<p>
                            User <span>{formData.email}</span> has Added and an invitation email has been
                            sent
                          </p>}
                        className="error_modal"
                        onCancel={() => dispatch.notiModel.setModalData({})}
                    />,
                    className: "add_user_modal"
                    });
                    this.getAllUsers({ page: 1 });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async updateUser(payload) {
            const { formData, handleModal, id } = payload;
            await axiosAdmin.put(`/api/admin/users/${id}`, formData).then(res => {
                if (res.data.code === 200) {
                    handleModal(null, false);
                    this.getAllUsers({ page: 1 });
                    dispatch.notiModel.setModalData({ 
                        Component: <AddedUserPop
                        title={"Edit A System User"}
                        subTitle={<p>
                            User <span>{formData.email}</span> has been edited successfully
                          </p>}
                        className="error_modal"
                        onCancel={() => dispatch.notiModel.setModalData({})}
                    />,
                    className: "add_user_modal"
                    });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async deleteUser(payload) {
            const { id, handleInfoPop } = payload;
            await axiosAdmin.delete(`/api/admin/users/${id}`).then(res => {
                if (res.data.code === 200) {
                    handleInfoPop({});
                    this.getAllUsers({ page: 1 });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async disableUser(payload) {
            const { formData, record, handleInfoPop } = payload;
            await axiosAdmin.post(`/api/admin/users/${record._id}/change-status`, formData).then(res => {
                if (res.data.code === 200) {
                    let disableDesc = `User <b>${record.email}</b> has been disabled temporarily. You may click on the icon to enable access again anytime`,
                    enableDesc = `User <b>${record.email}</b> has been enabled`;
                    handleInfoPop({ type: "disableUser", data: { title: `User ${formData.isActive ? "Enabled" : "Disabled" }`, 
                    subTitle: formData.isActive ? enableDesc : disableDesc
                }});
                this.getAllUsers({ page: 1 });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async resetUserPassword(payload) {
            const { email, handleInfoPop } = payload;
            await axiosAdmin.post('/api/auth/forgot-password/sendLink', {email, type: "reset"}).then(res => {
                if (res.data.code === 200) {
                    handleInfoPop({ type: "disableUser", data: { title: `Password Reset Request`, 
                    subTitle: `A password reset email has been sent to the user ${email}`
                }});
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async ghostLogin(payload) {
            const { id } = payload;
            await axiosAdmin.get(`/api/admin/users/${id}/login`).then( async (res) => {
                if (res.data.code === 200) {
                    cookies.set(`c-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                    window.open('/client', '_blank');
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
    }),
};

export default userModel;