import muddlogo from "./image/mudd_logo.png";
import moblie_footer_logo from "./image/footer.png";
import password_show from "./image/eyes_hide.svg";
import password_hide from "./image/eyes_show.svg";
import profile_image from "./image/profile.png";
import search from "./image/search.svg";
import close from "./image/close.svg";
import edit from "./image/edit.svg";
import unlock from "./image/unlock.svg";
import lock from "./image/lock-icon.svg";
import delete_icon from "./image/delete.svg";
import loginas from "./image/loginas.svg";
import user from "./image/user.svg";
import filter from "./image/filter.svg";
import filter_close from "./image/filter_close.svg";
import moblie_menu from "./image/menu.svg";
import heartFill from "./image/heart-fill.svg";
import heart from "./image/heart.svg";
import delete_white from "./image/delete_white.svg";
import downloads from "./image/downloads.svg";
import list from "./image/list_show.svg";
import file_btn from "./image/file_btn.svg";
import close_input from "./image/close_input.svg";

export const commanImage = {
  muddlogo,
  moblie_footer_logo,
  password_show,
  password_hide,
  profile_image,
  search,
  close,
  edit,
  lock,
  delete_icon,
  loginas,
  user,
  filter,
  filter_close,
  moblie_menu,
  unlock,
  heartFill,
  heart,
  delete_white,
  downloads,
  list,
  file_btn,
  close_input,
};
