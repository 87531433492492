import moment from 'moment/moment';
import CustomButton from '../../CustomButton';

const InfoPop = ({ onCancel, title, subTitle, className, date }) => {
  return (
    <div className={`modal_main notification active ${className}`}>
      <div className='modal'>
        <div className='modal_detail'>
          <div className='modal_title'>
            <p>{title}</p>
          </div>
          {date && <div className="modal_date" >{moment().format('ddd, MMM DD YYYY')}</div>}
          <div className='modal_desc'>
            <p>{subTitle}</p>
          </div>
          <div class='notification_btn'>
            <CustomButton
              className='primary notification_button'
              value='OK'
              name='submit'
              type={'submit'}
              onClick={() => onCancel({})}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPop;
