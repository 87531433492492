const notiModel = {
    state: {
        visible: false,
        Component: <div>no content</div>
    },
    reducers: {
        setModalData(state, payload) {
            return { ...state, visible: !state.visible, Component: (payload.Component || state.Component), className: (payload.className || "user_disabled") } ;
        },
    },
    effects: () => ({
    }),
};

export default notiModel;