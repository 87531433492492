import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import CustomModal from '../../components/CustomModal';
import store from '../../store';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import {
  expireTime,
  isAuthenticated,
  isClientAuthenticated
} from '../../utils';
import { useSelector } from 'react-redux';
import { clientNav } from '../../routes/data/menulist';

const cookies = new Cookies();

const ClientLayout = ({ children, handleNavigate, ...rest }) => {
  const [active, setActive] = useState({
    path: window.location.pathname,
    toggle: true
  });
  const [navigationList, setNavigaionList] = useState([]);
  const [togglePwd, setTogglePwd] = useState(false);
  const modal = useSelector((state) => state.notiModel);
  const user = useSelector((state) => state.authModel.client);
  const { visible, Component, className } = modal;
  const { dispatch } = store;

  useEffect(() => {
    initNav();
  }, [user]);

  const initNav = () => {
    if (isAuthenticated()) {
      setNavigaionList(clientNav);
    } else {
      let navData = [...clientNav];
      navData = navData.reduce((acc, cur) => {
        let temp = acc;
        switch (cur.title) {
          case 'LMS':
            if (user.LMS) {
              temp = [...acc, cur];
            }
            break;
          case 'MI4':
            if (user.MI4) {
              temp = [...acc, cur];
            }
            break;
          default:
            temp = acc;
            break;
        }
        return temp;
      }, []);
      setNavigaionList(navData);
    }
  };

  const logOut = () => {
    cookies.remove('c-auth-token', {
      path: '/',
      expires: expireTime(),
      secure: true,
      sameSite: 'strict'
    });
    handleNavigate('/', { replace: true });
    localStorage.clear();
    if (isAuthenticated()) {
      window.close();
    }
  };

  const changePasswordPop = () => {
    setTogglePwd(true);
  };

  const handleSubNav = ({ id, path }, index) => {
    let isPathExist = navigationList[index].subMenu.findIndex(
      (data) => data.path === active.path
    );
    if (isPathExist > -1 && active.toggle) {
      setActive({ path, toggle: false });
    } else {
      setActive({ path, toggle: true });
      //handleNavigate(path);
    }
  };

  const changePassword = (e) => {
    let formData = { ...e };
    delete formData.confirmPassword;
    dispatch.authModel.changePassword({
      formData,
      onCancel: handlePwdPop,
      admin: false
    });
  };

  const handlePwdPop = () => {
    setTogglePwd(!togglePwd);
  };

  return (
    <>
      <div>
        <Header
          handleNavigate={handleNavigate}
          logOut={logOut}
          changePasswordPop={changePasswordPop}
          togglePwd={togglePwd}
          user={user}
          handlePwdPop={handlePwdPop}
          changePassword={changePassword}
        />
        <div
          className={`${
            isClientAuthenticated() || isAuthenticated() ? 'dashboard_main' : ''
          }`}
        >
          <div
            className={`${
              isClientAuthenticated() || isAuthenticated()
                ? 'dashboard_content'
                : ''
            }`}
          >
            {(isClientAuthenticated() || isAuthenticated()) && (
              <Sidebar
                active={active}
                handleSubNav={handleSubNav}
                navList={navigationList}
                handleNavigate={handleNavigate}
              />
            )}
            {children}
          </div>
        </div>

        <Footer />
      </div>
      <CustomModal
        className={className}
        visible={visible}
        onCancel={() => dispatch.notiModel.setModalData()}
      >
        {Component}
      </CustomModal>
    </>
  );
};

export default ClientLayout;
