import axiosClient from "../../api/axiosClient";
import CustomNotification from "../../components/CustomNotification";
import { downloadCSVData } from "../../utils";

const fileListModel = {
    state: {
        docs: [],
        page: 0,
        totalDocs: 0,
        limit: 10,
        columns: [],
        totalPages: 0,
    },
    reducers: {
        setListData(state, payload) {
            if (payload.docs.length > 0) {
                let columns = payload.headers.reduce((acc, cur) => [...acc, { title: cur.name, dataIndex: cur.name, key: cur.name, width: cur.width  }] ,[])
                return { ...state, ...payload, columns } ; 
            } else {
                return { ...state, ...payload  } ;
            }
        },
    },
    effects: (dispatch) => ({
        async getFileLists({ route, download }) {
            await axiosClient.get(`${route}`).then(res => {
                if (download && !res?.data?.code) {
                    downloadCSVData('File_List', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(res?.data));
                } else {
                    if (res.data.code === 200) {
                        this.setListData(res.data.data);
                    } else {
                        CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                    }
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async getNcoaFileLists(payload) {
            await axiosClient.post(`/api/client/ncoa/list`, payload).then(res => {
                if (payload.download && !res?.data?.code) {
                    downloadCSVData('Ncoa_List', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(res?.data));
                } else {
                    if (res.data.code === 200) {
                        this.setListData(res.data.data);
                    } else {
                        CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                    }
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async getJobFileLists(payload) {
            await axiosClient.post(`/api/client/jobdrop/list`, payload).then(res => {
                if (payload.download && !res?.data?.code) {
                    downloadCSVData('Job_Drop_List', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(res?.data));
                } else {
                    if (res.data.code === 200) {
                        this.setListData(res.data.data);
                    } else {
                        CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                    }
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
    }),
};

export default fileListModel;