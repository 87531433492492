import React from 'react';
import { ReactComponent as Traingle } from '../../assets/image/triangle.svg';
import { Link } from 'react-router-dom';
import { normalizeUrl } from '../../utils';

function Sidebar({ navList, handleNavigate, active, handleSubNav }) {
  return (
    <div className='sidebar_panel'>
      <ul className='user_panel'>
        {navList.map((nav, index) => {
          if (nav.subMenu.length > 0) {
            let check = (nav.subMenu.filter(data => {
              return String(active.path).toLowerCase().split('/').includes(String(data.activeName).toLowerCase())
            }).length > 0);
            
            return (
              <>
                <li
                  className={`${check ? 'active lms' : ''}`}
                  onClick={() => handleSubNav(nav, index)}
                >
                  <a to=''>{nav.title}</a>
                  <Traingle
                    className={`${
                      check && active.toggle
                        ? 'traingle'
                        : 'traingle active'
                    }`}
                  />
                </li>
                {check && active.toggle && (
                  
                  <ul className='sub_menu'>
                    {
                       nav.subMenu.map(sub => {
                        return <li onClick={() => handleNavigate(sub.path)} className={`${String(window.location.pathname).toLowerCase().split('/').includes(String(sub.activeName).toLowerCase()) ? "active" : ""}`} >
                        <a to=''>{sub.title}</a>
                      </li>
                      })
                    }
                  </ul>
                )}
              </>
            );
          } else {
            return (
              <li
                onClick={() => handleNavigate(nav.path)}
                key={nav.id}
                className={`${
                  nav.path === window.location.pathname ? 'active' : ''
                }`}
              >
                <Link to=''>{nav.title}</Link>
              </li>
            );
          }
        })}
        {/* <li className=""><Link to="">user</Link></li> */}
        {/* <li className="active lms"><Link to="">LMS</Link>
          <ul className='sub_menu'>
            <li><Link to="">Clients</Link></li>
            <li><Link to="">JOBS</Link></li>
            <li><Link to="">NCOA</Link></li>
          </ul>
        </li> */}
      </ul>

      {/* <Menu
        selectedKeys={[active]}
        mode='inline'
        theme='dark'
        inlineCollapsed={false}
        items={navList}
        onClick={(e) => handleClick(e)}
        expandIcon={handleToggle}
      /> */}
    </div>
  );
}

export default Sidebar;
