import React from 'react';
import CustomButton from '../../components/CustomButton';
import { commanImage } from '../../assets';
import { Form as SignForm, Input } from 'antd';
import { ReactComponent as PasswordShow } from '../../assets/image/eyes_hide.svg';
import { ReactComponent as PasswordHide } from '../../assets/image/eyes_show.svg';
import { rules } from '../../utils/rules';
import store from '../../store';
import CustomPassword from '../../components/CustomPassword';

export default function ChangePassword({ handlePwdPop, changePassword }) {
  const [form] = SignForm.useForm();

  const reset = () => {
    form.resetFields();
  };

  return (
    <>
      <div className={`modal_main change_password       `}>
        <div className='modal'>
          <div className='modal_detail'>
            <div className='modal_close'>
              <img
                onClick={() => handlePwdPop()}
                src={commanImage.close}
                alt='close'
              />
            </div>
            <div className='modal_title'>
              <p>Change Password</p>
            </div>
            <div className='modal_from'>
              <SignForm
                onFinish={changePassword}
                form={form}
                initialValues={{}}
              >
                <SignForm.Item
                  name='currentPassword'
                  rules={rules.currentPassword}
                  className={`password`}
                >
                  <CustomPassword
                    form={form}
                    label={'Current Password'}
                    placeholder={'current password'}
                    name={'currentPassword'}
                    className='user_input'
                    iconRender={(visible) =>
                      visible ? <PasswordHide /> : <PasswordShow />
                    }
                  />
                </SignForm.Item>

                <SignForm.Item
                  name='newPassword'
                  rules={rules.newPassword}
                  className={`password`}
                  dependencies={['currentPassword']}
                >
                  <CustomPassword
                    form={form}
                    label={'New Password'}
                    placeholder={'new password'}
                    name={'newPassword'}
                    className='user_input'
                    iconRender={(visible) =>
                      visible ? <PasswordHide /> : <PasswordShow />
                    }
                  />
                </SignForm.Item>

                <SignForm.Item
                  name='confirmPassword'
                  rules={rules.confirmPassword}
                  className={`password`}
                  dependencies={['newPassword']}
                >
                   <CustomPassword
                    form={form}
                    label={'Confirm Password'}
                    placeholder={'confirm password'}
                    name={'confirmPassword'}
                    className='user_input'
                    iconRender={(visible) =>
                      visible ? <PasswordHide /> : <PasswordShow />
                    }
                  />
                </SignForm.Item>

                <div className='user_button'>
                  <CustomButton
                    type={'button'}
                    className='clear'
                    value='clear'
                    name='clear'
                    onClick={() => reset()}
                  />
                  <CustomButton
                    className='primary'
                    value='Save'
                    name='submit'
                    type={'submit'}
                  />
                </div>
              </SignForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
