import axios from "axios";

const axiosPublic = axios.create({
    baseURL: process.env.REACT_APP_END_POINT,
});

axiosPublic.defaults.timeout = 600000;

axiosPublic.interceptors.response.use(
    function (response) {
        return response;
    },
);

export default axiosPublic;