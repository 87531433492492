import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import authModel from './model/auth.model';
import notiModel from './model/noti.model';
import userModel from './model/user.model';
import clientModel from './model/client.model';
import fileListModel from './model/fileList.model';
import vinModel from './model/vin.model';
import dmsModel from './model/dms.model';

const loadingPlugin = createLoadingPlugin();

const store = init({
    redux: {
        rootReducers: {
            RESET: (state, action) => {},
        },
    },
    plugins: [loadingPlugin],
    models: { authModel, notiModel, userModel, clientModel, fileListModel, vinModel, dmsModel }
});

export default store