import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import CustomModal from '../../components/CustomModal';
import store from '../../store';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { expireTime, isAuthenticated, isClientAuthenticated } from '../../utils';
import { useSelector } from 'react-redux';
import { userNav } from '../../routes/data/menulist';

const cookies = new Cookies();

const AdminLayout = ({ children, handleNavigate, ...rest }) => {
  const [togglePwd, setTogglePwd] = useState(false);
  const modal = useSelector((state) => state.notiModel);
  const user = useSelector((state) => state.authModel.admin);
  const { visible, Component, className } = modal;
  const { dispatch } = store;

  const logOut = () => {
    cookies.remove('x-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
    cookies.remove('c-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
    localStorage.clear();
    handleNavigate('/', { replace: true });
  };

  const changePasswordPop = () => {
    setTogglePwd(true);
  };

  const changePassword = (e) => {
    let formData = { ...e };
    delete formData.confirmPassword;
    dispatch.authModel.changePassword({ formData, onCancel: handlePwdPop, admin: true });
  };

  const handlePwdPop = () => {
    setTogglePwd(!togglePwd);
  }

  return (
    <>
      <div>
        <Header
          handleNavigate={handleNavigate}
          logOut={logOut}
          changePasswordPop={changePasswordPop}
          togglePwd={togglePwd}
          user={user}
          handlePwdPop={handlePwdPop}
          changePassword={changePassword}
        />
        <div
          className={`${
            isClientAuthenticated() || isAuthenticated() ? 'dashboard_main' : ''
          }`}
        >
          <div
            className={`${
              isClientAuthenticated() || isAuthenticated()
                ? 'dashboard_content'
                : ''
            }`}
          >
            {(isClientAuthenticated() || isAuthenticated()) && (
              <Sidebar
                navList={userNav}
                handleNavigate={handleNavigate}
              />
            )}
            {children}
          </div>
        </div>

        <Footer />
      </div>
      <CustomModal
        className={className}
        visible={visible}
        onCancel={() => dispatch.notiModel.setModalData()}
      >
        {Component}
      </CustomModal>
    </>
  );
};

export default AdminLayout;
