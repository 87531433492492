import React from "react";
import { Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import CustomLayout from "../../Layout/CustomLayout";
import Loader from "../../Layout/Loader";
import { isAuthenticated, isClientAuthenticated } from "../../utils";
import { ClientRouteList } from "../data/routes";
import ClientLayout from "../../Layout/ClientLayout";

const checkAccess = (accessType, client) => {
  let access = false;

  if (accessType.includes("LMS") && client.LMS) {
    access = true;
  } else if (accessType.includes("MI4") && client.MI4) {
    access = true;
  }

  return access;
};

const clientRouter = (handleNavigate, client) => {
  const PrivateRouters = ({ accessType, children }) => {
    let token = isClientAuthenticated();
    if (!token) {
      return <Navigate to="/" />;
    } else if (
      !checkAccess(accessType, client) &&
      Object.keys(client || {}).length > 0 &&
      !isAuthenticated()
    ) {
      return <Navigate to="/access" />;
    }
    return children;
  };

  return ClientRouteList.map(
    ({ component: Component, path, layout, pageType, accessType }, key) => {
      switch (layout) {
        case "custom": {
          return (
            <Route
              path={path}
              element={
                <PrivateRouters accessType={accessType}>
                  <CustomLayout handleNavigate={handleNavigate}>
                    <React.Suspense fallback={<Loader />}>
                      <Component
                        client={client}
                        pageType={pageType}
                        handleNavigate={handleNavigate}
                      />
                    </React.Suspense>
                  </CustomLayout>
                </PrivateRouters>
              }
              key={key}
            />
          );
        }
        default: {
          return (
            <Route
              path={path}
              element={
                <PrivateRouters accessType={accessType}>
                  <ClientLayout handleNavigate={handleNavigate}>
                    <React.Suspense fallback={<Loader />}>
                      <Component
                        client={client}
                        handleNavigate={handleNavigate}
                      />
                    </React.Suspense>
                  </ClientLayout>
                </PrivateRouters>
              }
              key={key}
            />
          );
        }
      }
    }
  );
};

export default clientRouter;
