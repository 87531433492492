import InfoPop from "../ActionPop/InfoPop"

const CustomNotification = ({ title, subTitle, dispatch, date }) => {
    dispatch.notiModel.setModalData({
        Component: <InfoPop
        date={date}
        title={title}
        subTitle={subTitle}
        className="error_modal"
        onCancel={() => dispatch.notiModel.setModalData({})}
    />
    })
}

export default CustomNotification