import React from "react";
import { Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Loader from "../../Layout/Loader";
import { isAuthenticated } from "../../utils";
import { PrivateRouteList } from "../data/routes";
import AdminLayout from "../../Layout/AdminLayout";

const privateRouter = (handleNavigate) => {

    const PrivateRouters = ({ children }) => {
        let token = isAuthenticated();
        if (!token) {
            return <Navigate to="/" />;
        }
        return children;
    };


    return (
        PrivateRouteList.map(({ component: Component, path, name }, key) => (
            <Route
                path={path}
                element={
                    <PrivateRouters>
                        <AdminLayout handleNavigate={handleNavigate} title={name}>
                        <React.Suspense fallback={<Loader />}>
                            <Component handleNavigate={handleNavigate} />
                            </React.Suspense>
                        </AdminLayout>
                    </PrivateRouters>
                }
                key={key}
            />
        ))
    )
};

export default privateRouter