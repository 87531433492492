import axiosAdmin from '../../api/axiosAdmin';
import axiosClient from '../../api/axiosClient';
import CustomNotification from '../../components/CustomNotification';

const dmsModel = {
    state: {
        dmsUserList: {
            docs: [],
            page: 1,
            totalDocs: 0,
            totalPages: 0,
            limit: 10,
        }
    },
    reducers: {
        setDmsStatsData(state, payload) {
            return { ...state, [payload.name]: { ...payload.data } };
        }
    },
    effects: (dispatch) => ({

        async getDmsUserList(payload) {
            const { page, search = '', isAdmin = false } = payload
            const req = `page=${page}&search=${search}`
            return await (isAdmin ? axiosAdmin : axiosClient)
                .get(`/api/reports?${req}`)
                .then((res) => {
                    if (res.data.code === 200) {

                        const { page, } = res.data.data

                        const data = { ...res.data.data, page: Number(page), }

                        this.setDmsStatsData({ name: 'dmsUserList', data });
                        return data
                    } else {
                        CustomNotification({
                            title: 'Info',
                            subTitle: res.data.message,
                            dispatch
                        });
                    }
                })
                .catch((err) => {
                    CustomNotification({
                        title: 'Error',
                        subTitle: err.message,
                        dispatch
                    });
                });
        },

        async getReportRequest(payload) {
            const { isAdmin = false } = payload
            return await (isAdmin ? axiosAdmin : axiosClient)
                .get(`/api/reports/report-request`)
                .then((res) => {
                    if (res.data.code === 200) {
                        // this.getDmsUserList({ page: 1 })
                        CustomNotification({
                            date: true,
                            title: 'DMS Stats Request Submitted',
                            subTitle:
                                'Once this process is completed, the DMS provider will send notification regarding progress and file delivery and we will intimate you.',
                            dispatch
                        });
                        return res.data
                    } else {
                        CustomNotification({
                            title: 'Info',
                            subTitle: res.data.message,
                            dispatch
                        });
                    }
                })
                .catch((err) => {
                    CustomNotification({
                        title: 'Error',
                        subTitle: err.message,
                        dispatch
                    });
                });
        },
    })
}


export default dmsModel;